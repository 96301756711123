.input--password {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  .input--password--icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    width: 1.5rem;
    right: 2rem;
  }
}
