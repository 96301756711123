.orders--filter--wrapper {
  width: 100vw;
  height: 100vh;
  display: -ms-grid;
  display: grid;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-line-pack: center;
      align-content: center;
  z-index: 1000;
  .orders--filter--body--inner {
    display: block;
    position: relative;
    background-color: #fff;
    border: #6ab04c 1px solid;
    border-radius: 20px;
    .modal--wrapper {
      padding-bottom: 1rem;
    }
  }
  .orders--filter--body {
    display: block;
    position: relative;
    max-height: 68vh;
    overflow-y: scroll;
  }
  .orders--filter--body {
    border-bottom: solid 1px #6ab04c;
    border-top: solid 1px #6ab04c;
  }
  .orders--filter--footer {
    padding: 2rem 0 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .orders--filter--body,
  .orders--filter--footer {
    padding: 1rem 1rem;
  }
  .modal--wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 1rem 1rem 0;
    position: relative;
    z-index: 100;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    .table--modal--close {
      cursor: pointer;
      border-radius: 8px;
      display: block;
      position: relative;
      padding: 4px 4px 4px 6px;
      color: #6ab04c;
      border: 1px solid #6ab04c;
      z-index: 120;
      background-color: #fff;
    }
  }
  .orders--filter--checkbox {
    padding: 0.5rem 0;
  }
  .orders--filter--date {
    padding-left: 0.5rem;
  }
  .workshopFilter--search {
    opacity: 1;
    margin: 0 !important;
    display: block;
    position: relative;
    padding-right: 10px;
  }
  .workshopFilter--period {
    display: -ms-grid;
    display: grid;
    position: relative;
    row-gap: 1rem;
  }
  .filter--inputElement {
    margin: 0 0 1rem !important;
  }
  .filter--workshopItems {
    width: 100%;
    margin-top: 1rem;
    span {
      cursor: pointer;
    }
    -webkit-column-gap: 3px;
       -moz-column-gap: 3px;
            column-gap: 3px;
  }
  .filter--workshopItem {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    padding: 4px 10px;
    margin: 0 3px 5px;
    background-color: #6ab04c;
    border-radius: 10px;
    color: #fff;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    span {
      border-left: 1px solid #f6f6f6;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      padding: 1px 2px 1px 5px;
      font-size: 14px;
      font-weight: 600;
    }
  }

}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
.orders--filter--icon {
  left: 1rem;
  bottom: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  background-color: #6ab04c;
  padding: 1rem;
  border-radius: 50%;
  cursor: pointer;
  z-index: 800;
  opacity: 0.8;
}
@media (max-width: 540px) {
  .orders--filter--wrapper {
    -webkit-box-pack: normal;
        -ms-flex-pack: normal;
            justify-content: normal;
    .orders--filter--body--inner {
      height: 100vh;
      width: 100%;
      border-radius: 0px;
    }
  }
}
