$gray-lighten: #f6f6f6;
$black: #000;
$gray: #9b9b9b;
input[type="text"],
input[type="email"],
input[type="tel"],
input[type="number"],
input[type="date"],
input[type="search"],
textarea {
     outline: none !important;
    -webkit-appearance: none !important;
}
input {
    border: none !important;
	border-radius: 0;
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    color: -internal-light-dark(black, white);
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    -webkit-appearance: auto;
       -moz-appearance: auto;
            appearance: auto;
    background-color: -internal-light-dark(rgb(255, 255, 255), rgb(59, 59, 59));
    -webkit-rtl-ordering: logical;
    cursor: text;
}
*::-ms-clear {
	display: none;
}

.textarea {
	height: 150px;
	resize: none;
	border: 0;
	width: 100%;
	font-size: 14px;
	font-weight: 500;
	padding-left: 30px;
	padding-top: 20px;
	background-color: $gray-lighten;
	color: $gray;
	-webkit-transition: .25s all;
	transition: .25s all;

	&:focus {
		color: $black;
		background-color: #eaeaea;
	}

	&::-webkit-input-placeholder {
		font-family: inherit;
	}

	&::-moz-placeholder {
		font-family: inherit;
	}

	&:-ms-input-placeholder {
		font-family: inherit;
	}

	&::placeholder {
		font-family: inherit;
	}

	&.textarea--white {
		background-color: #fff;
	}

	&.textarea--sm {
		height: 100px;
	}
}


.input {
	width: 100%;
	font-family: inherit;
	background-color: $gray-lighten;
	padding-left: 30px;
	font-size: 14px;
	font-weight: 500;
	height: 60px;
	color: $gray;
	-webkit-transition: .25s all;
	transition: .25s all;

	&:focus {
		color: $black;
		background-color: #eaeaea;
        border: none !important;
	}

	&::-webkit-input-placeholder {
		font-family: inherit;
	}

	&::-moz-placeholder {
		font-family: inherit;
	}

	&:-ms-input-placeholder {
		font-family: inherit;
	}

	&::placeholder {
		font-family: inherit;
	}

    
	&.input--white {
        background-color: #fff;
	}
    
	&.input--sm {
        width: 200px;
	}
}

@media (max-width: 768px) {
    .input{
    height: 50px !important;
    font-size: 12px !important;
    padding-left: 20px !important;
    }
}

textarea {
	border-radius: 0;
}
