.supply--wrapper {
  display: block;
  .supply--inner--wrapper {
    display: -ms-grid;
    display: grid;
    row-gap: 2rem;
    .supply--header {
      color: #fff;
      position: fixed;
      width: 100%;
      font-weight: 600;
      font-family: 1rem;
      background-color: #6ab04c;
      height: -webkit-min-content;
      height: -moz-min-content;
      height: min-content;
      padding: 1.6rem 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
    }
    .supply--body {
      display: -ms-grid;
      display: grid;
      row-gap: 0.3rem;
      
      .supply--goods,
      .delivery--goods,
      .supply--goods--title {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: minmax(10rem, 1fr) 1.5rem minmax(2rem, 6rem);
        grid-template-columns: minmax(10rem, 1fr) 1.5rem minmax(2rem, 6rem);
        span {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          padding-top: 0.7rem;
          padding-bottom: 0.7rem;
        }
        .input {
          padding: 1rem 0 1rem 21px !important;
          height: -webkit-max-content !important;
          height: -moz-max-content !important;
          height: max-content !important;
          width: 30% !important;
        }
      }
      .delivery--goods--title{
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: minmax(10rem, 1fr) minmax(2rem, 6rem);
        grid-template-columns: minmax(10rem, 1fr) minmax(2rem, 6rem);
        margin-bottom: 0.5rem;
        background-color: #6ab04c;
        padding: 0.5rem 0.3rem;
        color: #fff;
      }
      .delivery--goods{
        -ms-grid-columns: minmax(10rem, 1fr) minmax(2rem, 6rem);
        grid-template-columns: minmax(10rem, 1fr) minmax(2rem, 6rem);
        border-bottom: 1px solid #6ab04c;
        .input{
          width: 40px !important;
        }
      }
      .supply--goods--title {
        -ms-grid-columns: minmax(6rem, 1fr) minmax(-webkit-max-content, auto) minmax(-webkit-max-content, auto);
        -ms-grid-columns: minmax(6rem, 1fr) minmax(max-content, auto) minmax(max-content, auto);
        grid-template-columns: minmax(6rem, 1fr) minmax(-webkit-max-content, auto) minmax(-webkit-max-content, auto);
        grid-template-columns: minmax(6rem, 1fr) minmax(max-content, auto) minmax(max-content, auto);
        font-size: 1rem;
        span:nth-child(2){
            padding-right: 10px;

        }
      }
      .supply--goods{
          border-bottom: 1px solid #dadada;
      }
      .goods--accepted {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        position: relative;
      }
      .item__add,
      .item__remove {
        margin-left: 5px !important;
        cursor: pointer;
        color: #fff;
        position: relative;
        background-color: #6ab04c;
        padding: 1px 8px 3px 7px;
        border-radius: 50%;
        font-weight: 700;
      }
      .item__add {
        top: 0rem;
        left: 0.3rem;
        -webkit-transform: rotateZ(90deg);
        transform: rotateZ(90deg);
      }
      .item__remove {
        top: 0rem;
        left: 0.3rem;
        -webkit-transform: rotateZ(-90deg);
        transform: rotateZ(-90deg);
      }
    }
    .supply--footer {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      margin-bottom: 1rem;
    }
  }
}
@media (min-width: 769px) {
  .supply--body {
    width: 768px;
    margin: 5rem auto 0 !important;
  }
}
@media (max-width: 776px) {
  .supply--body {
      margin: 5rem 0.3rem 0 0.7rem !important;
    .supply--goods--title {
      font-size: 14px !important;
      -webkit-column-gap: 16px;
         -moz-column-gap: 16px;
              column-gap: 16px;
    }
  }
}

@media (max-width: 540px) {
  .supply--body {
    .supply--goods {
      font-size: 12px;
    }
    .supply--goods--title {
      font-size: 12px !important;
      -webkit-column-gap: 10px;
         -moz-column-gap: 10px;
              column-gap: 10px;
    }
    .item__remove{
        top: 0.2rem !important;
    }
  }
}
@media (max-width: 360px) {
  .supply--body {
    .supply--goods {
      font-size: 11px;
    }
    .supply--goods--title {
      font-size: 10px !important;
      -webkit-column-gap: 5px;
         -moz-column-gap: 5px;
              column-gap: 5px;
    }
  }
}