.navigationadmin {
  display: -ms-grid;
  display: grid;
  position: relative;
  border-bottom: 1px solid #6ab04c;
  .navigationadmin--link {
    font-size: 0.8rem;
  }

}
.navigationadmin--table {
  margin-right: 2rem;
  display: -ms-grid;
  display: grid;
  position: relative;
  -ms-grid-columns: (215px)[auto-fit];
      grid-template-columns: repeat(auto-fit, 215px);
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-decoration: none;
}
.navigationadmin,
.navigationadmin--title {
  .navigationadmin--link {
    text-decoration: none;
    color: gray;
  }
  .navigationadmin--link:hover {
    color: #000;
  }
}
.navigationadmin--table {
  li {
    white-space: nowrap;
    list-style: none;
    list-style-type: none;
    cursor: pointer;
    padding: 0 0 0.5rem;
  }
}
.navigationadmin--title {
  .navigationadmin--link {
    font-size: 1.2rem;
  }
  .navigationadmin--table {
    li {
      padding: 0.6rem 0;
    }
  }
}
@media (max-width: 560px) {
  .navigationadmin--title {
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
    .navigationadmin--link {
      font-size: 0.9rem;
    }
    .navigationadmin--table {
      li {
        padding: 0.3rem 0;
      }
    }
  }
}
