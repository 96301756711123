.admin--orders {
  display: -ms-grid;
  display: grid;
  position: relative;
  margin: 1rem auto;
  padding: 1rem 2rem;
  max-width: 1000px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-grid-columns: 30px 1fr;
      grid-template-columns: 30px 1fr;
  border-bottom: 1px solid #6ab04c;
  .orders--indicator {
    width: 8px;
    font-size: 0.7rem;
    height: 3px;
    border-radius: 50%;
    border: 1px solid #fff;
    padding: 5px 4px;
    margin-right: 0.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
  }
  .orders--indicator--red {
    -webkit-animation: blinkRed 1.5s linear infinite;
            animation: blinkRed 1.5s linear infinite;
    background-color: rgb(210, 20, 55);
  }
  .orders--indicator--green {
    -webkit-animation: blinkGreen 1.5s linear infinite;
            animation: blinkGreen 1.5s linear infinite;
    background-color: #6ab04c;
  }
  .orders-label {
    font-size: 0.9rem;
    display: -ms-grid;
    display: grid;
    grid-gap: 10px;
    gap: 10px;
    -ms-grid-columns: minmax(200px, 1fr) minmax(110px, 1fr);
        grid-template-columns: minmax(200px, 1fr) minmax(110px, 1fr);
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .orders-button {
    font-size: 0.8rem;
    font-weight: 500;
    padding: 7px 18px;
  }
}
@media (max-width: 768px) {
  .admin--orders {
    width: auto;
    padding: 1rem 0;
    margin: 2rem 1rem;
  }
}
@media (max-width: 375px) {
  .admin--orders {
    .orders-label {
      -ms-grid-columns: 1fr;
          grid-template-columns: 1fr;
    }
  }
}
@-webkit-keyframes blinkGreen {
  0%,
  20% {
    background-color: #85da60;
  }
  10%,
  30% {
    background-color: #85da60;
    box-shadow: 0px 0px 10px 4px rgba(233, 244, 233, 0.6) inset;
  }
}
@keyframes blinkGreen {
  0%,
  20% {
    background-color: #85da60;
  }
  10%,
  30% {
    background-color: #85da60;
    box-shadow: 0px 0px 10px 4px rgba(233, 244, 233, 0.6) inset;
  }
}
@-webkit-keyframes blinkRed {
  0%,
  20% {
    background-color: rgb(210, 20, 55);
  }
  10%,
  30% {
    background-color: rgb(210, 75, 100);
    box-shadow: 0px 0px 10px 4px rgba(244, 233, 233, 0.6) inset;
  }
}
@keyframes blinkRed {
  0%,
  20% {
    background-color: rgb(210, 20, 55);
  }
  10%,
  30% {
    background-color: rgb(210, 75, 100);
    box-shadow: 0px 0px 10px 4px rgba(244, 233, 233, 0.6) inset;
  }
}
.orders--management--wrapper {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 900px;
  font-size: 0.9rem;
  .orders--management--designation {
    font-weight: 600;
  }
  .orders--management--product {
    .product--item {
      font-weight: 500;
      margin: 0.5rem 0;
      border-bottom: 1px solid #6ab04c;
      padding: 1rem 1rem 1rem 0;
    }
    .product--item--correspond {
      font-weight: 500;
      color: #6ab04c;
    }
    .product--item--notCorrespond {
      font-weight: 500;
      color: crimson;
    }
    .item__open,
    .item__close {
      cursor: pointer;
      color: #fff;
      position: relative;
      background-color: #6ab04c;
      border-radius: 50%;
      font-weight: 900;
      display: inline-block;
      -webkit-transition: 0.2s linear all;
      transition: 0.2s linear all;
    }
    .item__close {
      left: 1rem;
      padding: 0px 8px 2px 7px;
      -webkit-transform: rotateZ(180deg);
      transform: rotateZ(180deg);
    }
    .item__open {
      padding: 1px 9px 2px 7px;
      left: 1rem;
      -webkit-transform: rotateZ(270deg);
      transform: rotateZ(270deg);
    }
    .product--detail--wrapper {
      .product--detail--item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        position: relative;
        gap: 6px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        margin: 1rem 0;
      }
      .product--detail {
        display: -ms-grid;
        display: grid;
        position: relative;
        -ms-grid-columns: (minmax(200px, 1fr))[auto-fill];
            grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        grid-gap: 1rem;
        gap: 1rem;
        grid-column-gap: 4rem;
        -webkit-column-gap: 4rem;
           -moz-column-gap: 4rem;
                column-gap: 4rem;
        margin-top: 1rem;
        .input--productnumber {
          width: 60px !important;
        }
      }
      .input--productString {
        width: 90% !important;
        max-width: 400px;
      }
    }
  }
}
.orders--button {
  padding: 7px 10px;
}
.orders--table {
  font-size: 0.65rem;
  width: 95vw;
  table {
    th {
      font-size: 0.6rem;
    }
  }
}
@media (max-width: 560px) {
  .orders--management--wrapper {
    .orders--management--product {
      width: 400px;
      font-size: 0.85rem;
    }
  }
}
@media (max-width: 425px) {
  .orders--management--wrapper {
    .orders--management--product {
      width: 290px;
    }
  }
}
@media (max-width: 768px) {
  .orders--management--wrapper {
    .orders--management--product {
      max-width: 550px;
    }
  }
}
@media (max-width: 1024px) {
  .orders--management--wrapper {
    .orders--management--product {
      max-width: 750px;
    }
  }
}
@media (max-width: 2024px) {
  .orders--management--wrapper {
    .orders--management--product {
      max-width: 1000px;
    }
  }
}

.product--item--open {
  -webkit-animation: openProductInfo linear 1s;
          animation: openProductInfo linear 1s;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  opacity: 1;
}
.product--item--close {
  -webkit-animation: closeProductInfo cubic-bezier(0.65, 0.05, 0.36, 1) 1s;
          animation: closeProductInfo cubic-bezier(0.65, 0.05, 0.36, 1) 1s;
  height: 0px;
  opacity: 0.1;
}
.product--item--close,
.product--item--open {
  display: block;
  position: relative;
}

@-webkit-keyframes openProductInfo {
  0% {
    height: 10px;
    opacity: 0.1;
  }
  25% {
    height: 10vh;
  }
  50% {
    height: 20vh;
  }
  100% {
    opacity: 1;
    height: 100%;
  }
}

@keyframes openProductInfo {
  0% {
    height: 10px;
    opacity: 0.1;
  }
  25% {
    height: 10vh;
  }
  50% {
    height: 20vh;
  }
  100% {
    opacity: 1;
    height: 100%;
  }
}
@-webkit-keyframes closeProductInfo {
  0% {
    opacity: 1;
    height: 100%;
  }
  50% {
    height: 20vh;
  }
  75% {
    height: 10vh;
  }
  100% {
    opacity: 0.1;
    height: 10px;
  }
}
@keyframes closeProductInfo {
  0% {
    opacity: 1;
    height: 100%;
  }
  50% {
    height: 20vh;
  }
  75% {
    height: 10vh;
  }
  100% {
    opacity: 0.1;
    height: 10px;
  }
}
.table--wrapper {
  max-width: 95vw;
}
table {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  th{
    padding-left: 2px;
    padding-right: 2px;
  }
  td {
    padding: 0px;
    .table--input {
      width: auto;
      padding: 1px 11px !important;
      margin: 0px;
      font-size: 0.7rem !important;
    }
  }
}

@media (max-width: 768px) {
  .table--input {
    padding-left: 10px;
    margin: 0px;
  }
}
@media (max-width: 568px) {
  .table--wrapper {
    max-width: 95vw;
    overflow-x: scroll;
  }
  .table--input {
    height: 30px !important;
  }
}
