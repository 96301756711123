@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
body {
  overflow-x: hidden;
  font-family: "Montserrat", sans-serif;
}
.order__content {
  overflow-x: hidden !important;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: minmax(14rem, 1fr) 3rem minmax(20rem, 4fr);
  grid-template-columns: minmax(14rem, 1fr) minmax(20rem, 4fr);
  gap: 3rem;
  margin: 3rem 2rem 2rem;
  .input,
  .textarea,
  .label,
  li {
    font-family: "Montserrat", sans-serif;
    font-weight: 500 !important;
  }
  .selectric .label {
    font-size: 0.8rem !important;
    padding-left: 10px !important;
  }
  .selectric-scroll li {
    padding-left: 1rem !important;
  }
  .order__date {
    .selectric:first-child {
      width: 6rem !important;
    }
  }
  .textarea {
    font-family: "Montserrat", sans-serif !important;
    padding: 20px 30px !important;
  }

  .order__date {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 5.5rem 7.5rem 6.5rem;
    grid-template-columns: 5.5rem 7.5rem 6.5rem;
  }
  small {
    font-weight: 700;
    font-size: 0.8rem !important;
  }
  .label--sm {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 3vw;
    font-size: 1rem;
    color: #9b9b9b;
  }
  .label--mar {
    margin: 2vw 0 !important;
  }
  .label--left {
    font-weight: 700;
  }
  .textarea {
    margin: 1rem 2rem 4vw 0 !important;
    width: 80%;
  }
}

.order__info_comment {
  margin: 2rem 0 !important;
  .label {
    margin: 1rem 0 !important;
  }
  .input {
    margin: 1rem 0 0 !important;
  }
}
.order__content {
  .selectric,
  li {
    font-size: 0.8rem !important;
    text-transform: none !important;
  }
}
.form__order__info {
  .btn {
    margin: 1vw 0 5vw;
  }

  // width: 18rem;
  .order__info__item {
    max-height: 3rem;
    padding: 1rem 0;
    display: -ms-grid;
    width: 90%;
    column-gap: 3rem;
    display: grid;
    -ms-grid-columns: 70% 1fr 1fr;
    grid-template-columns: 70% 1fr 1fr;
    .info--count {
      position: relative;
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
    }
    .item__add,
    .item__remove {
      cursor: pointer;
      color: #fff;
      position: relative;
      background-color: #6ab04c;
      padding: 1px 8px 3px 7px;
      border-radius: 50%;
      font-weight: 900;
    }
    .item__add {
      top: 0.2rem;
      left: 4rem;
      -webkit-transform: rotateZ(90deg);
      transform: rotateZ(90deg);
    }
    .item__remove {
      top: 0.8rem;
      font-weight: 900;
      left: 4rem;
      -webkit-transform: rotateZ(-90deg);
      transform: rotateZ(-90deg);
    }

    .order__info__item__count {
      width: 2.3rem;
      .input {
        margin-left: 1rem;
      }
    }
  }
  .order__info__item:nth-child(n + 2) {
    border-top: 1px solid #6ab04c;
    width: min-content;
    max-width: 355px;
  }
  .order__info_comment {
    margin: 1rem 0;
    width: 17.5rem;
  }
}

@media (min-width: 1080px) {
  .order__content {
    margin: 3rem auto 2rem !important;
    max-width: 1000px !important;
  }
}

@media (max-width: 768px) {
  .order__content {
    margin-left: 3vw;
    -webkit-column-gap: 2rem !important;
    -moz-column-gap: 2rem !important;
    column-gap: 2rem !important;
    -ms-grid-columns: minmax(10rem, 10rem) 2rem minmax(20rem, 4fr);
    grid-template-columns: minmax(10rem, 10rem) minmax(20rem, 4fr);
  }
  .order__info__item {
    width: min-content;
    .item__add {
      top: 0.1rem !important;
    }
    .item__remove {
      top: 0.4rem !important;
    }
  }

  .form__order__info {
    .selectric {
      width: 30vw !important;
    }
    .selectric-wrapper {
      width: 30vw !important;
    }
  }
  .order__info__item:nth-child(n + 2) {
    width: min-content;
  }
}
@media (max-width: 560px) {
  .order__content {
    width: 90vw !important;
    margin-left: 2vw !important;
    margin-right: 2vw !important;
    -ms-grid-columns: 80vw !important;
    grid-template-columns: 80vw !important;
    padding-left: 1rem;

    .order__date {
      -ms-grid-columns: 10rem;
      grid-template-columns: 10rem;
    }
    .order__info__item {
      width: auto;
      .info--count {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        .input {
          margin: 0.5rem 1rem 2rem 0 !important;
          width: 10rem;
        }
        .item__add,
        .item__remove {
          left: 9rem;
          margin-left: 1rem;
        }
        .item__add {
          top: 0.3rem !important;
        }
        .item__remove {
          top: 0.8rem !important;
        }
      }
      -ms-grid-columns: 1fr !important;
      grid-template-columns: 1fr !important;
    }
    .order__info_comment {
      margin: 5rem 0 1rem !important;
      input {
        width: 78% !important;
      }
    }
  }
  .form__order__info {
    .selectric {
      width: 15rem !important;
    }
    .selectric-wrapper {
      width: 15rem !important;
    }
  }

  .order__info__item:nth-child(n + 2) {
    width: auto !important;
  }
}
