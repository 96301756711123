.alert {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  .alert-show {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-family: inherit;
    // height: 2rem;
    max-height: 10rem;
    min-height: 2rem;
    width: 50vw;
    max-width: 25rem;
    padding: 2.5rem 0.2rem 1.9rem 0.5rem;
    z-index: 1000;
    position: fixed;
    opacity: 0.9;
    color: #fff;
    font-size: 0.9rem;
    font-weight: 600;
    background-color: #6ab04c;
    -webkit-animation: show_on 3000ms ease-in-out;
    animation: show_on 3000ms ease-in-out;
    -webkit-animation: top 500ms ease-in-out;
    animation: top 500ms ease-in-out;
    border: #fff 1px solid !important;
  }

  @-webkit-keyframes show_on {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.9;
    }
    90% {
      opacity: 0.9;
    }
    100% {
      opacity: 0 !important;
    }
  }

  @keyframes show_on {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.9;
    }
    90% {
      opacity: 0.9;
    }
    100% {
      opacity: 0 !important;
    }
  }
  @-webkit-keyframes top {
    0% {
      top: 6rem;
    }
  }
  @keyframes top {
    0% {
      top: 6rem;
    }
  }
}
@media (max-width: 560px) {
  .alert-show {
    padding: auto 10px;
    width: 80vw !important;
  }
}
