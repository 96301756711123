.admin--title {
  margin-top: 2rem;
  display: -ms-grid;
  display: grid;
  position: relative;
  -ms-grid-column-align: center;
      justify-items: center;
  .admin--title--header {
    font-size: 1.2rem;
    font-weight: 800;
  }
}
