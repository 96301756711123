.selectric-scroll{
    li{
        background-color: #fff !important;
    }
    li:hover{
        background-color: #f6f6f6 !important;
    }
}
.order__date{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 1rem;
}
.selectric-js-select-time{
    .selectric{
        height: 3.875rem;
        .label{
            line-height: 3.875rem !important;
        }
        b{
            height: 3.875rem;
        }
        border-radius: 0 !important;
        border: 0 !important;
        background-color: #f6f6f6 !important; 
    }
}

.selectric-items{

    height: -webkit-fit-content !important;

    height: -moz-fit-content !important;

    height: fit-content !important;
    background-color: #f6f6f6;
    .selectric-scroll{
        height: -webkit-fit-content !important;
        height: -moz-fit-content !important;
        height: fit-content !important;
        max-height: 9.8rem !important;
        overflow: auto;
    }   

}
.order__content{

    .selectric{
        .label{
            margin-right: 40px !important;
        }
    }
}

@media (max-width: 768px) {
    .selectric-js-select-time{
        .selectric{
        height: 52px !important;
        .label{
            line-height: 52px !important;
        }
        b{
            height: 52px  !important;
        }
    }
    }
}