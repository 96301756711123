.settings--inputElement {
  position: relative;
  display: -ms-grid;
  display: grid;
  width: 200px;
  margin: 1rem 0;
}
.workshop--inputDate {
  display: -ms-grid;
  display: grid;
  position: relative;
  -ms-grid-columns: (minmax(250px, 1fr))[auto-fill];
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  margin: 1rem 0;
  -ms-grid-column-align: center;
      justify-items: center;
}
input[type="date"] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important;
  padding-right: 10px;
}
.workshop--form {
  display: -ms-grid;
  display: grid;
  position: relative;
}
.workshop--button {
  display: -ms-grid;
  display: grid;
  position: relative;
  -ms-grid-column-align: center;
      justify-items: center;
}
@media (max-width: 560px) {
  .workshop--inputDate {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -ms-grid-column-align: center;
        justify-items: center;
    margin-right: 1rem;
    //   grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}
