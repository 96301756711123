.statistics--inner {
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  .statistics--element {
    margin: 1rem;
    display: -ms-grid;
    display: grid;
    position: relative;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .statistics--doughnut {
    width: 20rem !important;
  }
  .statistics--lable {
    font-size: 0.8rem;
    color: rgb(153, 153, 153);
    text-align: center;
  }
}
.statistics--workshop {
  .statistics--workshop--label {
    font-size: 1.2rem;
    font-weight: 600;
  }
  .statistics--bar {
    display: block;
    position: relative;
    width: 90vw;
    max-width: 712px;
    margin: 1rem auto;
    row-gap: 1rem;
    padding: 1rem 0;
  }
  .statistics--workshop--doughnut {
    margin: 0 auto 1rem;
    display: -ms-grid;
    display: grid;
    position: relative;
    -ms-grid-columns: (minmax(250px, 1fr))[auto-fit];
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 10px;
    -ms-grid-column-align: center;
        justify-items: center;
    .workshop--doughnut {
      padding: 1rem 0;
      width: 18rem;
    }
  }
}
.statistics--nav {
  display: -ms-grid;
  display: grid;
  margin: 1rem auto 0.4rem;
  position: relative;
  max-width: 900px;
  gap: 20px;
  width: 400px;
  font-weight: 600;
  .statistics--nav--item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    gap: 20px;
    .nav__item {
      color: gray;
      text-decoration: none;
    }
    .nav__item:hover {
      color: #000;
    }
  }
  .statistics--nav--label{
    cursor: pointer;
    font-weight: 600;
    color: #000;
  }
}
.statistics--detail,
.statistics--inner,
.statistics--workshop {
  display: -ms-grid;
  display: grid;
  position: relative;
  margin: 2rem auto;
  max-width: 900px;
  width: 400px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 20px;
}
.statistics--detail {
  padding-bottom: 3rem;
}
.table--modal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  padding: 1rem;
  top: 13%;
  margin: 0 auto;
  width: 100%;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 99;
  .modal--wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 1rem 1rem 0;
    position: relative;
    z-index: 100;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
  .table--modal--wrapper {
    background-color: #fff;
    max-height: 62vh;
    border-radius: 19px;
    .table--wrapper {
      height: -webkit-max-content;
      height: -moz-max-content;
      height: max-content;
      overflow: scroll;
      display: block;
      position: relative;
      max-height: 57vh;
    }
    table {
      display: block;
      margin: 1rem 1rem 2rem;
      position: relative;
      height: -webkit-max-content;
      height: -moz-max-content;
      height: max-content;
    }
  }
  .table--modal--close {
    cursor: pointer;
    border-radius: 8px;
    display: block;
    position: relative;
    padding: 4px 4px 4px 6px;
    color: #6ab04c;
    border: 1px solid #6ab04c;
    z-index: 120;
    background-color: #fff;
  }
}
.statistics--workshop{
  padding: 0 10px;
}
.table--modal-inner {
  display: block;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  opacity: 0.4;
  overflow: hidden;
  background-color: #7d8376;
  z-index: 80;
}

@media (max-width: 721px) {
  .statistics--nav {
    width: 80vw;
    font-size: 0.8rem;
  }
  .statistics--detail {
    width: 100%;
  }
  .statistics--inner {
    width: 100%;
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
  }
  .table--modal {
    font-size: 0.8rem;
    width: 90%;
  }
}
@media (max-width: 520px) {
  .statistics--workshop {
    margin: 10px auto;
    width: 90vw;
  }
  .table--modal {
    font-size: 0.7rem;
    width: 90%;
    table {
      margin: 2rem 1rem;
    }
  }
}
@media (max-width: 420px) {
  .table--modal {
    font-size: 0.6rem;
  }
}
@media (max-width: 420px) {
  .table--modal {
    font-size: 0.6rem;
  }
}
