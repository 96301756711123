.product--input {
  //width: 90% !important;
  height: 100% !important;
  padding-right: 10px;
}
// .product--input--wrapper {
//   datalist {
//     // display: block;
//     position: absolute;
//     max-height: 9.8rem !important;
//     overflow: auto;
//   }
//   option {
//     background-color: #fff !important;
//   }
//   option:hover {
//     background-color: #f6f6f6 !important;
//   }
// }
@media (max-width: 560px) {
  .product--input {
    width: 83% !important;
  }
}
