.login--external--wrapper {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  .login--inner--wrapper {
    display: block;
    position: absolute;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    padding-bottom: 1rem;
    top: calc(50% - 70vh / 2 - 2px);
    width: 30rem;
    box-shadow: 0px 0px 44px -17px #6ab04c;
    .login--header {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      position: relative;
      height: 15%;
      background-color: #6ab04c;
      color: #fff;
      font-weight: 600;
      font-size: 1.2rem;
      width: 100%;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      z-index: 200;
    }
    .login--body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      position: relative;
      margin-top: 3vh;
      margin-left: 3rem;
      row-gap: 1rem;
      input[type="number"]::-webkit-outer-spin-button,
      input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input[type="number"],
      input[type="number"]:hover,
      input[type="number"]:focus {
        -webkit-appearance: none;
                appearance: none;
        -moz-appearance: textfield;
      }
      .input {
        width: calc(90% - 2rem);
      }
    }
    .login--footer {
      margin-top: 1rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
  }
}
@media (max-width: 560px) {
  .login--inner--wrapper {
    width: 85vw !important;
  }
  .login--body {
    margin-top: 1rem !important;
    margin-left: 5vw !important;
    .input {
      width: calc(90% - 4vw) !important;
    }
  }
}
@media (max-width: 410px) {
  .login--inner--wrapper {
    width: 100% !important;
    height: 100% !important;
    top: 0 !important;
  }
}
