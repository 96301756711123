
$primary-color: #6ab04c;
button,
.btn {
	position: relative;
	display: inline-block;
	border-radius: 0;
	border: 0;
	-webkit-transition: .3s all;
	transition: .3s all;
	background-color: transparent;
	font-family: inherit;
	font-size: 14px;
	text-align: center;
	vertical-align: middle;

	> * {
		position: relative;
	}

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border-radius: 100px;

		height: 0;
		//transform: scaleY(0);
		-webkit-transform-origin: center top;
		        transform-origin: center top;
		//transition: transform .25s ease;
		-webkit-transition: all .25s ease;
		transition: all .25s ease;
	}

	&.is-loading {
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			border-radius: 100px;
			// background-image: url('../img/preloader/preloader-main-gray.gif');
			background-size: 30px 30px;
			background-repeat: no-repeat;
			background-position: center center;
			background-color: inherit;
			z-index: 2;
		}
	}
}

.btn-primary {
	background-color: #6ab04c;
	color: #fff;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 700;
	padding: 16px 40px;
	border-radius: 100px;
	overflow: hidden;

	&:before {
		background-color: #6fbe4d;
	}

	&:hover {

		@media (min-width: 768px) {
			&:before {
				//transform: scaleY(1);
				height: 100%;
			}
		}
	}

	&.is-loading {
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			border-radius: 100px;
			// background-image: url('../img/preloader/preloader-main-white.gif');
			background-size: 30px 30px;
			background-repeat: no-repeat;
			background-position: center center;
			background-color: inherit;
			z-index: 2;
		}
	}

	span {
		position: relative;
	}
}

.btn-uppercase {
	text-transform: uppercase;
}

.btn-transparent-icon {
	font-size: 12px;
	color: #6ab04c;
	border: 1px solid #eeeeee;
	border-radius: 100px;
	padding: 10px 18px 8px 13px;
	white-space: nowrap;
	overflow: hidden;

	&:hover {
		@media (min-width: 768px) {
			border-color: #f6f6f6;

			&:before {
				//transform: scaleY(1);
				height: 100%;
			}
		}
	}

	&:before {
		background-color: #f6f6f6;
	}

	.icon-envelope {
		width: 24px;
		height: 21px;
	}

	.icon-letter {
		display: inline-block;
		width: 24px;
		height: 21px;
		// background-image: url('../img/icons/envelope.svg');
		vertical-align: middle;
		margin-right: 2px;
	}
}


[data-logIn] {
	white-space: nowrap;

	span {
		pointer-events: none;
	}

	.icon-user {
		width: 16px;
		height: 11px;
		// background-image: url('../img/icons/user.png');
		background-repeat: no-repeat;
		margin-right: 5px;
		margin-top: -2px;
	}
}

.btn-search {
	width: 65px;

	> * {
		pointer-events: none;
	}

	@media (max-width: 768px) {
		width: 50px;
	}
}

.btn-nav-catalog {
	color: #fff;
	text-align: center;
	font-weight: 500;
	white-space: nowrap;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;

	&:hover {
		@media (min-width: 768px) {
			.icon-burger span:nth-child(2) {
				width: 20px;
			}
		}
	}

	@media (max-width: 768px) {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-flow: column;
		    flex-flow: column;
		-webkit-box-pack: center;
		    -ms-flex-pack: center;
		        justify-content: center;
		height: 60px;
		width: 81px;
		padding-left: 20px;
		-webkit-box-align: start;
		    -ms-flex-align: start;
		        align-items: flex-start;
	}

	@media (max-width: 420px) {
		width: 81px;
		height: 40px;
		padding-left: 0;
		-webkit-box-pack: center;
		    -ms-flex-pack: center;
		        justify-content: center;
		-webkit-box-align: center;
		    -ms-flex-align: center;
		        align-items: center;
	}

	&.is-active .icon-burger {

		@media (max-width: 768px) {
			span:nth-child(2) {
				opacity: 0;
			}

			span:nth-child(1) {
				-webkit-transform: rotate(45deg) translate(4px, 4px);
				        transform: rotate(45deg) translate(4px, 4px);
			}
			span:nth-child(3) {
				-webkit-transform: rotate(-45deg) translate(4px, -5px);
				        transform: rotate(-45deg) translate(4px, -5px);
			}
		}
	}

	.icon-burger {
		margin-right: 15px;
		pointer-events: none;

		@media (max-width: 768px) {
			margin-right: 0;
		}

		span {
			display: block;
			background-color: #fff;
			height: 2px;

			@media (max-width: 768px) {
				-webkit-transition: .3s all;
				transition: .3s all;
			}
		}

		span:first-child {
			width: 20px;
		}

		span:nth-child(2) {
			width: 12px;
			margin-top: 4px;
			-webkit-transition: .3s all;
			transition: .3s all;

			@media (max-width: 768px) {
				width: 20px;
			}
		}

		span:last-child {
			display: none;
			margin-top: 4px;

			@media (max-width: 768px) {
				display: block;
			}
		}
	}

	.text {

		@media (max-width: 768px) {
			display: none;
		}
	}
}

.btn-basket {
	padding-left: 30px;
	padding-right: 10px;
	font-weight: 500;
	color: #fff;

	@media (min-width: 1120px) {
		padding-left: 48px;
	}

	&:hover {
		@media (min-width: 768px) {
			.btn-icon:before {
				opacity: 1;
				-webkit-transform: scale(1);
				        transform: scale(1);
				background-color: #85c469;
			}
		}
	}

	@media (max-width: 768px) {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		    -ms-flex-align: center;
		        align-items: center;
	}

	@media (max-width: 420px) {
		padding-left: 0;
	}

	span {
		pointer-events: none;
	}

	.btn-icon {
		position: relative;

		&:after {
			content: '';
			height: 200%;
			position: absolute;
			top: -50%;
			left: -50%;
			width: 200%;
		}

		&:before {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			-webkit-transform: scale(0);
			        transform: scale(0);
			width: 50px;
			height: 50px;
			border-radius: 100%;
			opacity: 0;
			background-color: transparent;
			margin-top: -25px;
			margin-left: -25px;
			-webkit-transition-duration: .15s;
			        transition-duration: .15s;
			-webkit-transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
			        transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
			-webkit-transition-property: opacity, -webkit-transform;
			transition-property: opacity, -webkit-transform;
			transition-property: transform, opacity;
			transition-property: transform, opacity, -webkit-transform;
		}

		svg {
			position: relative;
		}

		.badge {
			position: absolute;
			top: -11px;
			right: -20px;
			font-size: 10px;
			font-weight: 600;
			color: #fff;
			background-color: #000;
			border-radius: 100%;
			padding: 2px 4px;
			min-width: 18px;
			min-height: 18px;
			text-align: center;
			-webkit-animation: pulse .7s forwards ease;
			        animation: pulse .7s forwards ease;
		}
	}

	.btn-icon svg {
		width: 20px;
		height: 22px;
		fill: #fff;
	}

	.price {
		margin-left: 27px;

		@media (max-width: 768px) {
			display: none;
		}
	}
}

.btn-success {
	background-color: #6ab04c;
	color: #fff !important;
	text-transform: uppercase;
	font-size: 11px;
	font-weight: 700;
	padding: 18px 43px;
	border-radius: 100px;
	overflow: hidden;

	&:before {
		background-color: #6fbe4d;
	}

	&:hover {

		@media (min-width: 768px) {

			&:before {
				//transform: scaleY(1);
				height: 100%;
			}
		}
	}

	span {
		position: relative;
	}

	&.btn--sm {
		padding: 18px 30px;
	}

	&.is-loading {
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			border-radius: 100px;
			// background-image: url('../img/preloader/preloader-main-white.gif');
			background-size: 30px 30px;
			background-repeat: no-repeat;
			background-position: center center;
			background-color: inherit;
			z-index: 2;
		}
	}
}

.btn-link {
	text-transform: uppercase;
	font-size: 11px;
	font-weight: 700;
	text-align: right;
	color: #000;

	&:hover {
		color: $primary-color;
	}

	&-primary {
		color: $primary-color;
	}

	&--white {
		color: #fff;

		&:hover {
			color: #000;
		}
	}
}

a.btn-order {
	line-height: 48px;
}

.btn-order {
	border-radius: 100%;
	width: 50px;
	height: 50px;
	background-color: #6ab04c;
	overflow: hidden;
	white-space: nowrap;
	min-height: 50px;

	&.btn-order-secondary {
		border-radius: 100px;
		width: auto;
		font-size: 11px;
		text-transform: uppercase;
		color: #fff;
		font-weight: 700;
		min-width: 164px;

		@media (max-width: 768px) {
			min-width: 50px;
			width: 50px;
			height: 50px;

			span {
				display: none;
			}
		}

		&:before {
			border-radius: 100px;
		}
	}

	&.is-loading {
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			border-radius: 100px;
			// background-image: url('../img/preloader/preloader-main-white.gif');
			background-size: 30px 30px;
			background-repeat: no-repeat;
			background-position: center center;
			background-color: inherit;
			z-index: 2;
		}
	}

	> * {
		position: relative;
	}

	span {
		vertical-align: middle;
		display: inline-block;
		margin-left: 10px;
	}

	&:hover {
		@media (min-width: 768px) {

			&:before {
				//transform: scaleY(1);
				height: 100%;
			}
		}
	}

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #6fbe4d;
		border-radius: 100%;

		//transform: scaleY(0);
		-webkit-transform-origin: center top;
		        transform-origin: center top;
		//transition: transform .25s ease;
	}

	svg {
		width: 20px;
		height: 22px;
		fill: #fff;
		vertical-align: middle;
	}
}

.btn-notification {
	border-radius: 100%;
	width: 50px;
	height: 50px;
	border: 1px solid #6ab04c;
	overflow: hidden;
	white-space: nowrap;
	text-align: center;
	line-height: 48px;

	&.btn-notification-secondary {
		border-radius: 100px;
		width: auto;
		font-size: 11px;
		text-transform: uppercase;
		// color: $primary-color;
		font-weight: 700;
		min-width: 249px;

		@media (max-width: 1120px) {
			min-width: 50px;
			width: 50px;
			height: 50px;

			span {
				display: none;
			}
		}

		&:before {
			border-radius: 100px;
		}
	}

	> * {
		position: relative;
	}

	span {
		vertical-align: middle;
		display: inline-block;
		margin-left: 10px;
	}

	&:hover {
		border-color: $primary-color;
	}

	svg {
		width: 18px;
		height: 24px;
		fill: $primary-color;
		vertical-align: middle;
	}
}

.btn-to-favorite {
	border: 1px solid #eeeeee;
	border-radius: 100%;
	width: 50px;
	height: 50px;
	line-height: 46px;

	&:hover {
		background-color: #f6f6f6;
		border-color: #f6f6f6;
	}

	svg {
		width: 20px;
		height: 18px;
		vertical-align: middle;
	}
}
.btn-secondary {
	cursor: pointer;
	color: #6ab04c;
	font-size: 11px;
	font-weight: 700;
	text-transform: uppercase;
	border: 1px solid rgba($primary-color, .4);
	border-radius: 100px;
	padding: 12px 27px;

	&:hover {
		border: 1px solid rgba($primary-color, 1);
	}

	svg {
		fill: $primary-color;
		width: 14px;
		height: 14px;
		vertical-align: middle;
		margin-right: 13px;

		&.icon-refresh {
			width: 20px;
			height: 20px;
			margin-top: 2px;
			margin-bottom: 2px;
		}
	}

}

.btn-play {
	width: 100px;
	height: 100px;
	border-radius: 100%;
	background-color: $primary-color;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	overflow: hidden;

	&:before {
		background-color: #6fbe4d;
	}

	@media (max-width: 768px) {
		width: 60px;
		height: 60px;
	}
}

.btn-form-arrow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
}
$black: #000;
.btn-search-call {
	display: none;

	@media (max-width: 768px) {
		display: block;
		width: 100px;
		text-align: center;
	}

	svg {
		position: relative;
		top: 2px;
	}
}

.btn-gray {
	background-color: rgba(#9b9b9b, .95);
	font-size: 11px;
	font-weight: 700;
	text-transform: uppercase;
	color: #fff;
	white-space: nowrap;
	padding: 13px 33px;
	border-radius: 100px;

	&:hover {
		background-color: rgba(#9b9b9b, 1);
	}
}

.btn-to-favorite-lg {
	font-size: 11px;
	text-transform: uppercase;
	font-weight: 700;
	color: $black;

	&:hover {
		color: #6ab04c;

		svg {
			fill: #6ab04c;
		}
	}

	&.is-active {

		svg {
			fill: $primary-color;
		}
	}

	span, svg {
		vertical-align: middle;
	}

	svg {
		-webkit-transition: .3s all;
		transition: .3s all;
		margin-right: 10px;
		overflow: visible;
	}
}
$gray-lighten: #9b9b9b;
$gray: #9b9b9b;
$gray-secondary: #9b9b9b;
.btn-circle-arrow {
	width: 50px;
	height: 50px;
	background-color: #f6f6f6;
	border-radius: 50%;
	line-height: 50px;
	text-align: center;

	@media (max-width: 420px) {
		background-color: transparent;
	}

	&.is-active {
		background-color: $black;

		@media (max-width: 420px) {
			background-color: transparent;
		}

		svg {
			fill: #fff;

			@media (max-width: 420px) {
				fill: #9b9b9b;
			}
		}

		span {
			-webkit-transform: rotate(180deg);
			        transform: rotate(180deg);
		}
	}

	span {
		display: block;
		position: relative;
		-webkit-transition: .3s all;
		transition: .3s all;
	}

	svg {
		width: 14px;
		height: 8px;
		fill: $black;
		-webkit-transition: .3s all;
		transition: .3s all;
	}
}

.btn-gray-light {
	background-color: $gray-lighten;
	text-transform: uppercase;
	color: $gray;
	font-weight: 700;
	font-size: 11px;
	padding: 17px 30px;
	border-radius: 100px;
	overflow: hidden;

	&:before {
		background-color: #efefef;
	}

	&:hover {

		&:before {
			//transform: scaleY(1);
			height: 100%;
		}
	}

	svg {
		fill: $gray;
		width: 20px;
		height: 20px;
		vertical-align: middle;
		margin-right: 11px;
	}

	.icon-arrow-left {
		width: 15px;
		height: 15px;
	}

	small {
		font-size: 100%;
	}

	.icon-close {
		fill: #cfcfcf;
		width: 12px;
		height: 12px;
	}
}

.btn-question {
	font-size: 11px;
	font-weight: 600;
	color: #fff;
	line-height: 18px;
	width: 18px;
	height: 18px;
	border-radius: 50%;
	background-color: $gray-secondary;
	text-align: center;

	&:hover {
		background-color: $primary-color;
	}
}

.btn-circle-green {
	background-color: #85c469;
	width: 50px;
	height: 50px;
	border-radius: 50%;

	@media (max-width: 420px) {
		background: transparent;
	}

	&.is-active {
		background-color: $black;
		-webkit-transform: rotate(180deg);
		        transform: rotate(180deg);

		@media (max-width: 420px) {
			background: transparent;
		}
	}

	svg {
		fill: #fff;
		width: 14px;
		height: 8px;

		@media (max-width: 420px) {
			fill: #bdeaaa;
		}
	}
}

.btn-white {
	background-color: #fff;
	color: $primary-color;
	text-transform: uppercase;
	font-size: 11px;
	font-weight: 700;
	padding: 18px 43px;
	border-radius: 100px;
	overflow: hidden;

	&:before {
		background-color: #f4ffef;
	}

	@media (min-width: 768px) {
		&:hover {

			&:before {
				height: 100%;
				//transform: scaleY(1);
			}
		}
	}

	&.btn--transparent {
		background-color: transparent;
		border: 1px solid #fff;
		color: #fff;

		@media (max-width: 420px) {
			padding: 14px 43px;
		}
	}

	span {
		position: relative;
	}
}

.btn-arrow-secondary {
	width: 50px;
	height: 50px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;

	@media (max-width: 420px) {
		color: $primary-color;
		font-size: 11px;
		font-weight: 700;
		text-transform: uppercase;
		border: 1px solid rgba($primary-color, .4);
		border-radius: 100px;
		padding: 12px 27px;
		width: auto;
		height: auto;
		white-space: nowrap;

		&:hover {
			border: 1px solid rgba($primary-color, 1);
		}

		svg {
			fill: $primary-color;
			width: 14px;
			height: 14px;
			vertical-align: middle;
			margin-right: 13px;
		}

		span {
			vertical-align: middle;
		}
	}

	.desktop {
		display: inline-block;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		background-color: $primary-color;
		text-align: center;

		@media (max-width: 420px) {
			display: none;
		}

		svg {
			stroke: none;
			fill: #fff;
			width: 8px;
			height: 8px;
		}
	}

	.mobile {

		@media (min-width: 420px) {
			display: none;
		}
	}
}

.btn-remove {
	width: 32px;
	height: 32px;
	text-align: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;

	&:hover {

		svg {
			fill: $black;
		}
	}

	svg {
		vertical-align: middle;
		width: 12px;
		height: 12px;
		-webkit-transition: .3s all;
		transition: .3s all;
		fill: #cfcfcf;
	}
}

.btn-change-vision {
	width: 85px;
	height: 60px;

	&.is-active {

		.state.state--default {
			opacity: 0;
			visibility: hidden;
		}

		.state.state--active {
			opacity: 1;
			visibility: visible;
		}
	}

	.state {
		position: absolute;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		        transform: translate(-50%, -50%);
		display: block;
		margin-top: 4px;
		-webkit-transition: .3s all;
		transition: .3s all;
		opacity: 0;
		visibility: hidden;

		@media (max-width: 420px) {
			margin-top: 7px;
		}

		&.state--default {
			opacity: 1;
			visibility: visible;
		}
	}

	.icon-unvision {
		width: 26px;
		height: 8px;
	}

	.icon-vision {
		width: 24px;
		height: 16px;
	}
}

[data-close-modal] span {
	pointer-events: none;
}

@-webkit-keyframes pulse {
	0% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}

	50% {
		-webkit-transform: scale(1.4);
		        transform: scale(1.4);
	}

	100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
}

@keyframes pulse {
	0% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}

	50% {
		-webkit-transform: scale(1.4);
		        transform: scale(1.4);
	}

	100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
}
