.modal--external--wrapper {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 200;
  .modal--inner--wrapper {
    position: fixed;
    border: 1px solid #fff;
    display: block;
    background-color: #fff !important;
    // position: absolute;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    padding-bottom: 1rem;
    top: calc(50% - 70vh / 2 - 2rem);
    width: 30rem;
    box-shadow: 0px 0px 77px -17px #6ab04c;
    z-index: 999;
    .modal--header {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      position: relative;
      height: 15%;
      background-color: #6ab04c;
      color: #fff;
      font-weight: 600;
      font-size: 1.2rem;
      width: 100%;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      padding: 0.5rem 0;
    }
    .modal--body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      position: relative;
      margin-top: 1rem;
      margin-left: 3rem;
      row-gap: 1rem;
      height: 55vh;
      overflow-y: scroll;
      .input {
        width: calc(90% - 5rem);
      }
    }
    .modal--footer {
      margin-top: 2rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: distribute;
          justify-content: space-around;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      .btn-primary {
        height: 40px;
        padding: 11px 40px;
      }
    }
  }
}
@media (max-width: 560px) {
  .modal--inner--wrapper {
    width: 85vw !important;
  }
  .modal--body {
    margin-left: 5vw !important;
    .input {
      width: 13.725rem !important;
    }
  }
}
@media (max-width: 410px) {
  .modal--inner--wrapper {
    width: 100% !important;
    height: 100% !important;
    top: 0 !important;
  }
}
