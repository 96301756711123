.control__settings {
  margin: 2rem auto;
  max-width: 1000px;
  .control__settings--checkbox {
    margin: 1rem 0 1rem 1rem;
    .costGoost--label--active, .costGoost--label--inactive {
      font-weight: 600;
      -webkit-transition: ease-in-out color;
      transition: ease-in-out color;
    }
    .costGoost--label--active {
      color: #6ab04c;
    }
    .costGoost--label--inactive{
      color: rgb(230, 30, 30);
    }
  }
  .control__settings--lable {
    font-weight: 600;
  }
  .control__settings--save {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    margin: 3rem 0;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
@media (max-width: 1024px) {
  .control__settings {
    margin: 2rem 1rem;
  }
}
